import type { ListType } from "types/type";
import type { DirectionType } from "components/Core/common/types";
import { Box, Flex, Image, Text } from "components/Core";
import { LandingSectionContainer } from "components/shared/LandingSectionContainer";
import { WhyChooseDetail } from "./WhyChooseDetail";

type Props = {
  title: string;
  subTitle: string;
  image: string;
  direction: DirectionType;
  isArabic: boolean;
  reasons: ListType;
};
export const WhyChooseNumuw = ({
  subTitle,
  image,
  title,
  direction,
  isArabic,
  reasons,
}: Props) => {
  return (
    <LandingSectionContainer
      // mt={["30px", "30px", "30px", "50px"]}
      height={"auto"}
    >
      <Flex
        mr={isArabic ? [0] : 0}
        direction={direction}
        flexDirection={["column"]}
        gap={[30]}
        width={["100%", "100%", "100%", "60%"]}
      >
        <Flex
          alignItems={isArabic ? "flex-end" : "flex-start"}
          flexDirection="column"
          width={["100%"]}
          gap={[16, 16, 16, 30]}
        >
          <Text width={["100%", "100%", "100%", "82%"]} variant="heading1">
            {title}
          </Text>
          <Text
            lineHeight={"26px"}
            fontSize={18}
            width={["100%", "100%", "100%", "82%"]}
          >
            {subTitle}
          </Text>
        </Flex>
        <Flex justifyContent={isArabic ? "flex-end" : "flex-start"}>
          <WhyChooseDetail reasons={reasons} />
        </Flex>
      </Flex>
      <Box
        flexGrow={1}
        borderRadius={15}
        width={["100%", "100%", "90%", "50%"]}
        bg="black.100"
        mt={[30, 30, 30, 0]}
      >
        <Image
          // src={families}
          src={image}
          width={["100%"]}
        />
      </Box>
    </LandingSectionContainer>
  );
};
