//Styles
import { Box, Button, Flex, Image, Text } from "components/Core";
import { LandingSectionContainer } from "components/shared/LandingSectionContainer";
import { routes } from "constants/index";
import { useNavigate } from "react-router-dom";

export const SchoolHero = ({
  title,
  description,
  isArabic,
  direction,
  heroImage,
  buttonLabel,
  headingVariant,
}) => {
  const navigate = useNavigate();
  const onClick = () => {
    navigate(routes.talkToAdvisor);
  };

  return (
    <LandingSectionContainer gap={[16]}>
      <Flex
        flexDirection={["column"]}
        width={["100%", "100%", "100%", "48%"]}
        direction={direction}
        mr={[0, 0, 0, 40]}
        gap={[16, 16, 16, 36]}
      >
        <Text
          textAlign={[isArabic ? "right" : "left"]}
          variant={headingVariant ?? "heading1"}
          lineHeight={["normal"]}
        >
          {title}
        </Text>
        <Text variant="charcoalBody">{description}</Text>
        {/* <Text variant="charcoalBody">{subDescription}</Text> */}
        <Button
          display={["none", "none", "none", "block"]}
          variant="getStarted"
          // my={[36, 36, 36, 15, 15, , 36]}
          width={["100%", "100%", "100%", "max-content"]}
          button
          onClick={onClick}
        >
          {buttonLabel}
        </Button>
      </Flex>

      <Image
        style={{ objectFit: "cover", objectPosition: "top" }}
        height={[300, 300, 340, 450]}
        width={["100%", "100%", "100%", "auto"]}
        src={heroImage}
      />
      <Button
        mt={16}
        display={["block", "block", "block", "none"]}
        variant="getStarted"
        width={["100%", "100%", "100%", "max-content"]}
        button
        onClick={onClick}
      >
        {buttonLabel}
      </Button>
      {/* </Box> */}
    </LandingSectionContainer>
  );
};
