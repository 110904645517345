import type { AreasWeProvideListType } from "types/type";
import type { DirectionType } from "components/Core/common/types";
import { LandingSectionContainer } from "components/shared/LandingSectionContainer";

import { AreasWeProvideSupportGrid } from "./AreasWeProvideSupportGrid";

type Props = {
  title: string;
  direction: DirectionType;
  isArabic: boolean;
  reasons: AreasWeProvideListType;
};
export const AccordionAreaWeProvideSupport = ({ title, reasons }: Props) => {
  return (
    <LandingSectionContainer height={"auto"} gap={[16, 16, 40]} pr={"0px"}>
      <AreasWeProvideSupportGrid title={title} reasons={reasons} />
    </LandingSectionContainer>
  );
};
