import { Box, CenterColumn, Flex, Text } from "components/Core";
import { Box as MuiBox } from "@mui/material";
import { LandingSectionContainer } from "components/shared/LandingSectionContainer";
import { useLocal } from "hooks/useLocal";
import { useMemo } from "react";
import Slider, { type Settings } from "react-slick";

// Add this import for testimonial images

const settings: Settings = {
  autoplay: true,
  infinite: true,
  autoplaySpeed: 1000,
  // adaptiveHeight: true,
  speed: 2000,
  slidesToShow: 3,
  slidesToScroll: 1,
  initialSlide: 0,
  responsive: [
    {
      breakpoint: 1200,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 1,
      },
    },
    {
      breakpoint: 900,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 1,
      },
    },
    {
      breakpoint: 600,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: false,
      },
    },
  ],
};

export const Testimonials = () => {
  const { t, isArabic, direction } = useLocal();

  const testimonials = useMemo(
    () => t("testimonials.testimonials", { returnObjects: true }),
    [isArabic]
  ) as Array<{ name: string; text: string }>;
  const width = useMemo(() => testimonials.length * 430, [testimonials.length]);
  return (
    <LandingSectionContainer minHeight={["auto"]}>
      {/* Add Testimonials Section */}
      <MuiBox
        height="100%"
        sx={{
          "& .slick-track": {
            pb: "25px !important",
            display: "flex !important",
            gap: ["5px", "33.9px"],
          },
          "& .slick-slide": {
            width: {
              xs: "100% !important",
              sm: "auto !important",
            },
            "& > div": {
              padding: ["0 2px", "0 10px"],
            },
          },
          width: "100%",
          overflow: "visible", // Change from hidden to visible
        }}
      >
        <Text
          color="typePurpure.200"
          textAlign="center"
          mb={[16, 16, 16, 30]}
          variant="heading1"
        >
          {t("testimonials.title")}
        </Text>

        <Slider {...settings}>
          {testimonials.map((testimonial, index) => (
            <CenterColumn
              display="flex !important"
              direction={direction}
              position="relative"
              flexDirection={["column"]}
              boxShadow="normal"
              alignItems="center"
              // bg="red"
              justifyContent="center"
              bg="white"
              p={4}
              // px={[4]}
              borderRadius={40}
              gap={10}
              // width="100%"
              // maxWidth={[300, 310, 330]}
              minWidth={[300, 300, 400]}
              m={["0 auto"]}
              height={"400px"}
              maxHeight={isArabic ? [300, 300, 250] : [400, 400, 340]}
              // scale={[1, 1, 1, 1, 1, 0.9, 1]}
              // style={{ scale: 0.5 }}
            >
              <Text fontSize={[12, 15]} variant="body2" mb={[2]}>
                "{testimonial.text}"
              </Text>
              <Text textAlign={["right"]} variant="body3" fontWeight="bold">
                - {testimonial.name}
              </Text>
            </CenterColumn>
          ))}
        </Slider>
      </MuiBox>
    </LandingSectionContainer>
  );
};
