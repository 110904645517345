import type { AreasWeProvideListType, ListType } from "types/type";
import { schoolHero, partnerWithNumuw } from "assets/images";
import { useSchoolLocal } from "./useSchoolLocal";
import { WhyPartnershipNumuw } from "./WhyPartnershipNumuw/WhyPartnershipNumuw";
import { useMemo } from "react";
import { AreasWeProvideSupport } from "components/NewDesignComponents/AreasWeProvideSupport/AreaWeProvideSupport";
import { PartnershipWithNumuw } from "./PartnershipWithNumuw/PartnershipWithNumuw";
import { SchoolHowItWorks } from "./SchoolHowItworks/SchoolHowItWorks";
import { Trusted } from "./Trusted/Trusted";
import { SchoolCTA } from "./SchoolCTA/SchoolCTA";
import { SchoolHero } from "./SchoolHero";
import { Mission } from "components";
import { Container } from "components/Core";
import { AreasWeProvideSupportGrid } from "components/NewDesignComponents/AreasWeProvideSupport/AreasWeProvideSupportGrid";
import { AccordionAreaWeProvideSupport } from "components/NewDesignComponents/AreasWeProvideSupport/AccordionAreaWeProvideSupport";

export const SchoolRoot = () => {
  const { direction, isArabic, t } = useSchoolLocal();
  const reasons = useMemo(
    () => t("whyPartnerNumuw.reasons", { returnObjects: true }),
    [isArabic]
  ) as ListType;

  const partnership = useMemo(
    () => t("whatWeOffer.steps", { returnObjects: true }),
    [isArabic]
  ) as ListType;

  const howItWorksSteps = useMemo(
    () => t("howItWorks.steps", { returnObjects: true }),
    [isArabic]
  ) as ListType;
  const areasWeProvideDetail = useMemo<AreasWeProvideListType>(
    () => t("supportAreas", { returnObjects: true }),
    [isArabic]
  );
  return (
    <Container gap={["50px"]} flexDirection={["column"]}>
      <SchoolHero
        heroImage={schoolHero}
        description={t("hero.heroDescription")}
        direction={direction}
        isArabic={isArabic}
        title={t("hero.title")}
        buttonLabel={t("buttons.talkToAdvisor")}
        headingVariant="heading2"
      />
      <Trusted />
      <WhyPartnershipNumuw
        direction={direction}
        image={partnerWithNumuw}
        isArabic={isArabic}
        reasons={reasons}
        subTitle={t("whyPartnerNumuw.subTitle")}
        title={t("whyPartnerNumuw.title")}
      />
      <AccordionAreaWeProvideSupport
        title={t("areasWeProvideSupport")}
        reasons={areasWeProvideDetail}
        direction={direction}
        isArabic={isArabic}
      />
      <PartnershipWithNumuw
        direction={direction}
        isArabic={isArabic}
        startNow={t("buttons.getInTouch")}
        steps={partnership}
        title={t("whatWeOffer.title")}
        hideNumbers
      />
      {/* <SchoolHowItWorks
        direction={direction}
        isArabic={isArabic}
        startNow={t("buttons.getInTouch")}
        steps={howItWorksSteps}
        title={t("howItWorks.title")}
        hideCta
      /> */}
      <Mission />
      <SchoolCTA />
    </Container>
  );
};
