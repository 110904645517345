export const currencyFormatter = ({
  amount,
  currency = "USD",
}: {
  amount: number | undefined;
  currency?: string;
}) => {
  return new Intl.NumberFormat("en-US", {
    style: "currency",
    currency,
    minimumFractionDigits: 0,
  }).format(amount ?? 0);
};
