import { system } from "styled-system";

const transformConfig = {
  transform: {
    property: "transform" as const,
    transform: (value: string) => `${value}`,
  },
};

export const transform = system(transformConfig);
