import { Close } from "@mui/icons-material";
import { Grid, IconButton, Modal, useMediaQuery } from "@mui/material";
import {
  Box,
  CenterColumn,
  CenterRow,
  Flex,
  Image,
  Text,
} from "components/Core";
import { useDisclosure } from "hooks/useDisclosure";
import { useLocal } from "hooks/useLocal";
import { useState } from "react";
import { theme } from "theme/theme";
import { useTheme } from "@mui/material";
import type { AreasWeProvideListType, Reason } from "types/type";
import {
  aggressionAndTrauma,
  anxiety,
  attention,
  autism,
  behavioral,
  communication,
  developmentalDelay,
  emotionalRegulation,
  parentGuides,
  socialInteraction,
} from "assets/images";

type Props = {
  reasons: AreasWeProvideListType;
  title: string;
};

const images = {
  0: aggressionAndTrauma,
  1: anxiety,
  2: attention,
  3: autism,
  4: behavioral,
  5: communication,
  6: developmentalDelay,
  7: emotionalRegulation,
  8: "",
  9: parentGuides,
  10: socialInteraction,
};
export const AreasWeProvideSupportGrid = ({ reasons, title }: Props) => {
  const { onOpen, open, onClose } = useDisclosure();
  const [selectedReason, setSelectedReason] = useState<{
    reason: Reason;
    index: number;
  }>({ reason: reasons[0], index: 0 });
  const { t, direction, isArabic } = useLocal();
  const muiTheme = useTheme();
  const downMd = useMediaQuery(muiTheme.breakpoints.down("md"));
  const upXs = useMediaQuery(muiTheme.breakpoints.up("xs"));
  return (
    <Grid
      borderRadius={"20px"}
      bgcolor={theme.colors.gray[200]}
      sx={{
        p: [2, 2, 3, 9],
        py: downMd ? 5 : [3, 3, 3, 9],
        width: "100%",
      }}
      //   py={20}
      container={upXs ? true : false}
      spacing={[1, 2]}
      columnSpacing={[0, 0, 2]}
      direction={isArabic ? "row-reverse" : "row"}
      // justifyContent="center"
    >
      <Text
        textAlign={["center"]}
        variant="heading1"
        mb={"15px"}
        direction={direction}
      >
        {title}
      </Text>
      {reasons.map((reason, index) => (
        <Grid
          lg={4}
          xl={3}
          xs={12}
          md={6}
          sx={{
            width: ["100%", "100%", 300, 300, 300, 345],
            m: downMd ? 1 : 0,
          }}
          item
          key={index}
          onClick={() => {
            setSelectedReason({ reason, index });
            onOpen();
          }}
        >
          <Flex
            direction={direction}
            flexDirection={["column"]}
            gap={16}
            variant="card"
          >
            <Text variant="heading4">{reason.rootTitle}</Text>
            <CenterRow gap={1}>
              <Text
                onClick={() => {
                  onOpen();
                  setSelectedReason({ reason, index });
                }}
                color="primary"
                width="max-content"
              >
                {t("learnMore")}
              </Text>
            </CenterRow>
          </Flex>
        </Grid>
      ))}
      <Modal open={open} onClose={onClose}>
        <Flex
          direction={direction}
          flexDirection={["column"]}
          gap={20}
          variant="modal-container"
          overflowY="scroll"
          maxHeight={"90vh"}
          hideScrollbar
          padding={[16, 16, 24]}
          pb={[20, 20, 30]}
        >
          <CenterRow justifyContent={["space-between"]} width={["100%"]}>
            <Text lineHeight="initial" width="max-content" variant="heading4">
              {selectedReason.reason?.rootTitle}
            </Text>
            <IconButton onClick={onClose}>
              <Close />
            </IconButton>
          </CenterRow>
          <Flex flexWrap={["wrap", "wrap", "wrap", "nowrap"]} gap={16}>
            <Image
              height={[300, 300, 300, "50%"]}
              width={["100%", "100%", "100%", "50%"]}
              maxHeight={300}
              style={{
                objectFit: "cover",
                objectPosition: "top",
              }}
              src={images[selectedReason.index as keyof typeof images]}
            />
            <CenterColumn height="100%" gap={10}>
              <Box>
                <Text
                  lineHeight={1.2}
                  fontSize={18}
                  fontWeight="bold"
                  minWidth="fit-content"
                >
                  {t("whatLooksLike")}:
                </Text>
                <Text lineHeight={1.2}>
                  {selectedReason?.reason?.whatLooksLikeDescr}
                </Text>
              </Box>
              <Box pb={[20]}>
                <Text
                  lineHeight={1.2}
                  fontSize={18}
                  fontWeight="bold"
                  minWidth="fit-content"
                >
                  {t("howWeCanHelp")}:
                </Text>
                <Text lineHeight={1.2}>
                  {selectedReason?.reason?.howWeCanHelpDescr}
                </Text>
              </Box>
            </CenterColumn>
          </Flex>
        </Flex>
      </Modal>
    </Grid>
  );
};
