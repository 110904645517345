export const theme = {
  breakpoints: ["320px", "576px", "768px", "992px", "1200px", "1400px"],
  colors: {
    mainText: "#262626",
    secondaryText: "#8c8c8c",
    primary: "#8450a0",
    gradient: {
      purple:
        "linear-gradient(150deg, #8450a0 0%, rgba(111, 29, 244, 0.4) 100%)",
    },
    secondary: "#7fc8ac",
    black: {
      900: "#000000",
      600: "#707070",
      500: "#a8a8a8",
      300: "#d9d9d9",
      200: "#f0f0f0",
      100: "#fafafa",
    },
    charcoal: "#333",
    white: "#ffffff",
    blue600: "#0595e7",
    error: "#dc3545",
    warning: "#ffc107",
    orange2: "#f9be4c",
    success: "#7fc8a7",
    typePurpure: {
      100: "#8450a0",
      200: "#8450A0",
      300: "#6d308d",
    },
    typeDarkblue: "#434da1",
    border: "#eeeeee",
    borderGray100: "#eeeeee",
    purple: {
      100: "#fafafc",
      200: "#eee9f3",
      300: "#e9d7fa",
    },
    gray: {
      100: "#f8f8fb",
      200: "#eff1f9",
      300: "#8fa0af",
    },
    stateSelected: "#f6f0fc",
    stateSuccess: "#4a960e",
    textPrimary: "#171717",
    textPrimaryLight: "#0e1114",
    textSecondary: "#9b9b9b",
    textGray: "#626262",
  },
  space: [0, 4, 8, 16, 32, 64, 128, 256],
  fontSizes: [12, 14, 16, 20, 24, 32, 48, 64],
  borders: ["1px solid", "1px solid #f8f8fb", "2px solid #8450A0"],
  radii: [
    "5px",
    "10px",
    "15px",
    "20px",
    "25px",
    "30px",
    "35px",
    "40px",
    "45px",
    "50px",
  ],
  shadows: {
    base: "0px 4px 4px 0px #00000040",
    themed:
      "0 10px 15px -3px rgba(111, 29, 244, 0.2), 0 4px 6px -4px rgba(111, 29, 244, 0.2)",
    normal: "0 1px 3px 0 rgb(0 0 0 / 0.1), 0 1px 2px -1px rgb(0 0 0 / 0.1)",
    sm: "0 1px 2px 0 rgb(0 0 0 / 0.05)",
    md: "0 4px 6px -1px rgb(0 0 0 / 0.1), 0 2px 4px -2px rgb(0 0 0 / 0.1)",
    lg: "0 10px 15px -3px rgb(0 0 0 / 0.1), 0 4px 6px -4px rgb(0 0 0 / 0.1)",
    xl: "0 20px 25px -5px rgb(0 0 0 / 0.1), 0 8px 10px -6px rgb(0 0 0 / 0.1)",
  },
  // Add more theme properties as needed
};
