import { Grid } from "@mui/material";
import {
  screeningStep,
  sessionsStep,
  growthStep,
  resourceStep,
} from "assets/images";
import { CenterColumn, CenterRow, Image, Text } from "components/Core";
import type { DirectionType } from "components/Core/common/types";
import { LandingSectionContainer } from "components/shared/LandingSectionContainer";
import { useLocal } from "hooks/useLocal";
import { TFunction } from "i18next";

type Props = {
  t: TFunction<Array<string>, undefined>;
  direction: DirectionType;
};
const stepsImage = {
  0: screeningStep,
  1: sessionsStep,
  2: growthStep,
  3: resourceStep,
};
export const GetStarted = ({ t, direction }: Props) => {
  const { isArabic } = useLocal();
  const steps = t("getStarted.steps", { returnObjects: true }) as Array<{
    title: string;
    description: string;
  }>;
  return (
    <LandingSectionContainer
      direction={direction}
      flexDirection={["column"]}
      gap={30}
    >
      <Text
        pl={isArabic ? 0 : [0, 0, 40]}
        pr={isArabic ? [0, 0, 40] : 0}
        textAlign={["center", "center", isArabic ? "right" : "left"]}
        variant="heading1"
        // width="max-content"
      >
        {t("getStarted.title")}
      </Text>
      <Grid rowGap={4} justifyContent="center" container spacing={2}>
        {steps?.map((step, index) => (
          <Grid item xs={12} md={5} lg={5} xl={3} key={index}>
            <CenterColumn gap={10} alignItems="center">
              <Image
                width={["100%", 300, 280]}
                src={stepsImage[index as keyof typeof stepsImage]}
                alt={step.title}
              />
              <CenterRow gap={"5px"}>
                <Text variant="numberHolder">{index + 1}</Text>
                <Text
                  maxWidth={280}
                  textAlign="center"
                  fontSize={[19, 25]}
                  // variant="heading4"
                  fontWeight={600}
                >
                  {step.title}
                </Text>
              </CenterRow>
              <Text
                maxWidth={280}
                textAlign="center"
                fontSize={[16, 16, 18]}
                lineHeight={1.2}
              >
                {step.description}
              </Text>
            </CenterColumn>
          </Grid>
        ))}
      </Grid>
    </LandingSectionContainer>
  );
};
