import { Box, Button, CenterColumn, Container, Text } from "components/Core";
import { LandingSectionContainer } from "components/shared/LandingSectionContainer";
import { currencyFormatter } from "utils";
import styled from "styled-components";
import { appNumuwGetStartedUrl } from "constants/index";
import { useLocal } from "hooks/useLocal";
import { theme } from "theme/theme";
const CardBackground = styled.div<{ variant: "left" | "center" | "right" }>`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  border-radius: 10px;
  z-index: -1;

  ${(props) => {
    switch (props.variant) {
      case "left":
        return `
          background: linear-gradient(135deg, ${props.theme.colors.purple[300]} 0%, ${props.theme.colors.white} 100%);
        `;
      case "center":
        return `
          background: linear-gradient(135deg, ${props.theme.colors.typePurpure[100]} 0%, ${props.theme.colors.typePurpure[300]} 100%);
        `;
      case "right":
        return `
          background: linear-gradient(135deg, ${props.theme.colors.black[200]} 0%, ${props.theme.colors.white} 100%);
        `;
    }
  }}
`;
const bgGradient = {
  left: `linear-gradient(135deg, ${theme.colors.purple[300]} 0%, ${theme.colors.white} 100%);`,
  center: `linear-gradient(135deg, ${theme.colors.typePurpure[100]} 0%, ${theme.colors.typePurpure[300]} 100%);`,
  right: `linear-gradient(135deg, ${theme.colors.black[200]} 0%, ${theme.colors.white} 100%);`,
};
const PriceCard = styled(CenterColumn)<{
  featured?: boolean;
  type: keyof typeof bgGradient;
}>`
  position: relative;
  overflow: hidden;
  transition: all 0.3s ease;
  border: 1px solid rgba(255, 255, 255, 0.2);
  // transform: ${(props) =>
    props.featured ? ["scale(1)", "scale(1)", "scale(1.05)"] : "scale(1)"};

  &:hover {
    transform: ${(props) =>
      props.featured ? "scale(1.05) translateY(-10px)" : "translateY(-10px)"};
  }
  background: ${(props) => bgGradient[props.type]};}
`;

const FeatureList = styled.ul`
  list-style: none;
  padding: 0;
  margin: 20px 0;
  text-align: left;
`;

const FeatureItem = styled.li<{ featured?: boolean; isArabic?: boolean }>`
  padding: 8px 0;
  display: flex;
  width: ${(props) => (props.isArabic ? "max-content" : "initial")};
  // align-items: center;
  max-width: 360px;
  color: ${(props) =>
    props.featured ? props.theme.colors.white : props.theme.colors.textPrimary};

  &:before {
    content: "✓";
    // transform: ${(props) => (props.isArabic ? "scaleX(-1.2)" : "none")};
    color: ${(props) =>
      props.featured
        ? props.theme.colors.white
        : props.theme.colors.typePurpure[100]};
    margin-${(props) => (props.isArabic ? "left" : "right")}: 10px;
}
`;
const Banner = styled(Box)`
  position: absolute;
  top: 20px;
  right: -35px;
  width: 150px;
  background: ${(props) => props.theme.colors.error};
  color: white;
  padding: 8px 0;
  text-align: center;
  transform: rotate(45deg);
  font-size: 13px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  font-weight: 700;

  &:before,
  &:after {
    content: "";
    position: absolute;
    width: 0;
    height: 0;
    border-style: solid;
    top: 0;
  }

  &:before {
    left: 0;
    border-width: 15px 0 0 15px;
    border-color: transparent transparent transparent
      ${(props) => props.theme.colors.success};
  }

  &:after {
    right: 0;
    border-width: 15px 15px 0 0;
    border-color: transparent ${(props) => props.theme.colors.success}
      transparent transparent;
  }
`;

const handleContactSupport = () => {
  let message = process.env.REACT_APP_CONTACT_FOR_EVALUATION_MSG;
  let phoneNumber = process.env.REACT_APP_CONTACT_SUPPORT_NUMBER;
  window.open(
    `https://api.whatsapp.com/send?phone=${phoneNumber}&text=${encodeURIComponent(
      message as string
    )}`,
    "_blank"
  );
};

export const Pricing = () => {
  const { direction, t, isArabic } = useLocal();
  return (
    <LandingSectionContainer
      direction={direction}
      flexDirection={["column"]}
      gap={4}
    >
      <Text
        variant="heading1"
        textAlign={
          isArabic ? ["right", "right", "center"] : ["left", "left", "center"]
        }
      >
        {t("pricing.title")}
      </Text>
      {/* First Card */}
      <Container gap={4} flexDirection={["column", "column", "column", "row"]}>
        <PriceCard
          type="left"
          order={[2, 2, 2, 1]}
          width={["100%", "100%", "100%", "33%"]}
          minHeight={["fit-content", "fit-content", 500]}
          boxShadow="normal"
          borderRadius={10}
          p={4}
          minWidth={["100%", "100%", "360px"]}
          // mt={[0, 0, 4]}
        >
          <CardBackground variant="left" />
          <Text variant="heading2" textAlign="center" mb={2}>
            {t("pricing.evaluations")}
          </Text>
          <Text variant="body1" textAlign="center" color="textSecondary" mb={4}>
            {t("pricing.contactForPricing")}
          </Text>
          <FeatureList>
            <Text
              width="max-content"
              fontWeight="bold"
              mb={1}
              fontSize={[16, 18]}
            >
              {t("pricing.customized")}:
            </Text>
            <FeatureItem isArabic={isArabic} style={{ lineHeight: 1.3 }}>
              {t("pricing.psychoEducational")}
            </FeatureItem>
            <FeatureItem isArabic={isArabic} style={{ lineHeight: 1.3 }}>
              {t("pricing.mentalHealth")}
            </FeatureItem>
            <FeatureItem isArabic={isArabic} style={{ lineHeight: 1.3 }}>
              {t("pricing.behavioral")}
            </FeatureItem>
          </FeatureList>
          <Button
            onClick={handleContactSupport}
            variant="secondary"
            width="100%"
            borderRadius={10}
          >
            {t("pricing.contactUs")}
          </Button>
        </PriceCard>

        {/* Center Card */}
        <PriceCard
          scale={[1, 1, 1.05]}
          type="center"
          order={[1, 1, 1, 2]}
          width={["100%", "100%", "100%", "33%"]}
          minHeight={["fit-content", "fit-content", 500]}
          boxShadow="lg"
          borderRadius={10}
          p={4}
          featured={true}
          minWidth={["100%", "100%", "360px"]}
        >
          <CardBackground variant="center" />
          <Text variant="heading2" textAlign="center" mb={2} color="white">
            {t("pricing.payAsYouGrow")}
          </Text>
          <Text
            gap={"5px"}
            display="flex"
            alignItems="center"
            justifyContent="center"
            variant="heading3"
            textAlign="center"
            mb={2}
            color="white"
          >
            <span style={{ fontSize: "12px", opacity: 0.8 }}>
              {t("pricing.startFrom")}
            </span>
            {currencyFormatter({ amount: 80 })}
            <span style={{ fontSize: "12px", opacity: 0.8 }}>
              /{t("pricing.session")}
            </span>
          </Text>
          <FeatureList>
            <FeatureItem isArabic={isArabic} featured>
              {" "}
              {t("pricing.freeAdvisoryCall")}
            </FeatureItem>
            <FeatureItem isArabic={isArabic} featured>
              {" "}
              {t("pricing.expertMatch")}
            </FeatureItem>
            <FeatureItem isArabic={isArabic} featured>
              {" "}
              {t("pricing.freeDiscoveryCall")}
            </FeatureItem>
            <FeatureItem isArabic={isArabic} featured>
              {t("pricing.progressTrackingTools")}
            </FeatureItem>
            <FeatureItem isArabic={isArabic} featured>
              {" "}
              {t("pricing.oneSession")}
            </FeatureItem>
          </FeatureList>
          <a target="_self" href={appNumuwGetStartedUrl}>
            <Button borderRadius={10} variant="secondary" width="100%">
              {t("pricing.bookYourFreeSession")}
            </Button>
          </a>
        </PriceCard>

        {/* Last Card */}
        <PriceCard
          type="right"
          order={[3]}
          width={["100%", "100%", "100%", "33%"]}
          minHeight={["fit-content", "fit-content", 500]}
          boxShadow="normal"
          borderRadius={10}
          p={4}
          // mt={[0, 0, 4]}
          minWidth={["100%", "100%", "360px"]}
        >
          <CardBackground variant="right" />
          <Banner> {t("pricing.comingSoon")}</Banner>
          <Text variant="heading2" textAlign="center" mb={2}>
            {t("pricing.numuwMembership")}
          </Text>
          <Text variant="body1" textAlign="center" color="textSecondary" mb={4}>
            {t("pricing.comingSoon")}
          </Text>
          <FeatureList>
            <FeatureItem isArabic={isArabic}>
              {t("pricing.fourSession")}
            </FeatureItem>
            <FeatureItem isArabic={isArabic}>
              {t("pricing.advisoryServices")}
            </FeatureItem>
            <FeatureItem isArabic={isArabic}>
              {t("pricing.careTeam")}
            </FeatureItem>
            <FeatureItem isArabic={isArabic}>
              {t("pricing.progressTrackingTools")}
            </FeatureItem>
            <FeatureItem isArabic={isArabic}>
              {t("pricing.exclusiveContent")}
            </FeatureItem>
          </FeatureList>
          <Button
            borderRadius={10}
            disabled
            variant="secondary"
            color="black.600"
            borderColor="black.600"
            width="100%"
          >
            {t("pricing.comingSoon")}
          </Button>
        </PriceCard>
      </Container>
    </LandingSectionContainer>
  );
};
