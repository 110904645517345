// Components
import {
  CompanyCooperation,
  InviteToFillForm,
  Growth,
  Mission,
} from "../components";

import { useMemo } from "react";
import PreConsultationPopup from "../components/PreConsultationPopup";
import { WhyChooseNumuw } from "components/NewDesignComponents/WhyChooseNumuw/WhyChooseNumuw";
import { HowItWorks } from "components/NewDesignComponents/HowItWorks/HowItWorks";
import { Trusted } from "components/NewDesignComponents/Trusted/Trusted";
import { Testimonials } from "components/NewDesignComponents/Testimonials/Testimonials";
import { whyChooseNumuw, heroImage } from "assets/images";
import { useLocal } from "hooks/useLocal";
// Core
import { useSearchParams } from "react-router-dom";
import { Pricing } from "components/NewDesignComponents/Pricing/Pricing";
import { AccordionAreaWeProvideSupport } from "components/NewDesignComponents/AreasWeProvideSupport/AccordionAreaWeProvideSupport";
import { SliderDoctorsV2 } from "components/SliderDoctorsV2";
import { LandingSectionContainer } from "components/shared/LandingSectionContainer";
import { Container, Flex } from "components/Core";
import { GetStarted } from "components/NewDesignComponents/GetStarted";

const MainPage = () => {
  const [searchParams, setSearchParams] = useSearchParams();

  const handlePreCloseConsultation = () => {
    searchParams.delete("modal");
    setSearchParams(searchParams);
  };

  const { direction, isArabic, t } = useLocal();
  const reasons = useMemo(
    () => t("whyChooseNumuw.reasons", { returnObjects: true }),
    [isArabic]
  );
  const areasWeProvideDetail = useMemo(
    () => t("supportAreas", { returnObjects: true }),
    [isArabic]
  );
  // For How it works
  const howItWorksSteps = useMemo(
    () => t("howItWorks.steps", { returnObjects: true }),
    [isArabic]
  );

  return (
    <Container flexDirection="column" gap={60}>
      <Growth
        heroImage={heroImage}
        description={t("hero.heroDescription")}
        direction={direction}
        isArabic={isArabic}
        title={t("hero.title")}
        buttonLabel={t("buttons.getStartedToday")}
        subDescription={t("hero.heroSubDescription")}
      />
      <AccordionAreaWeProvideSupport
        direction={direction}
        isArabic={isArabic}
        reasons={areasWeProvideDetail}
        title={t("areasWeProvideSupport")}
      />
      <WhyChooseNumuw
        direction={direction}
        image={whyChooseNumuw}
        isArabic={isArabic}
        reasons={reasons}
        subTitle={t("whyChooseNumuw.subTitle")}
        title={t("whyChooseNumuw.title")}
      />
      {/* <HowItWorks
        direction={direction}
        isArabic={isArabic}
        startNow={t("buttons.startNow")}
        steps={howItWorksSteps}
        title={t("howItWorks.title")}
      /> */}
      <GetStarted t={t} direction={direction} />
      <Testimonials />
      <Pricing />
      <Flex
        flexDirection="column"
        gap={10}
        pt={4}
        width={["100%"]}
        background="#F8F8F8"
      >
        <Container>
          <LandingSectionContainer
            width={["100%", "100%", "100%", "100%", "1210px"]}
          >
            <SliderDoctorsV2 />
          </LandingSectionContainer>
        </Container>
        <CompanyCooperation />
      </Flex>
      <Trusted />
      <Mission />
      <InviteToFillForm getConsultation={true} />
      <PreConsultationPopup
        onClose={handlePreCloseConsultation}
        open={searchParams.get("modal")}
      />
    </Container>
  );
};

export default MainPage;
