import {
  compose,
  space,
  layout,
  flexbox,
  color,
  border,
  position,
  type styleFn,
  typography,
  flexDirection,
  flexBasis,
  zIndex,
  boxShadow,
  textAlign,
  background,
  backgroundImage,
} from "styled-system";
import { gap } from "../customSystem/gap";
import { cursor } from "../customSystem/cursor";
import { direction } from "../customSystem/direction";
import { scale } from "../customSystem/scale";
import { transform } from "../customSystem/transform";

export const commonComposes = (extra?: Array<styleFn>) => {
  return compose(
    space,
    layout,
    flexbox,
    color,
    border,
    position,
    typography,
    zIndex,
    flexBasis,
    flexDirection,
    boxShadow,
    textAlign,
    background,
    backgroundImage,
    gap,
    cursor,
    direction,
    scale,
    transform,
    ...(extra ?? [])
  );
};
