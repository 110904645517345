import { Box, Image, Text } from "components/Core";
import { useLocal } from "hooks/useLocal";
import { motion } from "framer-motion";
import Styles from "./styles.module.scss";

import company_1 from "../../assets/images/company_1.png";
import company_2 from "../../assets/images/company_2.png";
import company_3 from "../../assets/images/company_3.png";
import company_4 from "../../assets/images/company_4.png";
import company_5 from "../../assets/images/company_5.png";
import { useState } from "react";

interface Company {
  id: number;
  img: string;
  alt: string;
}

const companies: Company[] = [
  { id: 1, img: company_1, alt: "Company 1 Logo" },
  { id: 2, img: company_2, alt: "Company 2 Logo" },
  { id: 3, img: company_3, alt: "Company 3 Logo" },
  { id: 4, img: company_4, alt: "Company 4 Logo" },
  { id: 5, img: company_5, alt: "Company 5 Logo" },
];

const CompanyCooperation: React.FC = () => {
  const { t, isArabic } = useLocal();

  return (
    <section className={Styles.wrapper}>
      <Text
        variant="heading2"
        textAlign={
          isArabic ? ["right", "right", "center"] : ["left", "left", "center"]
        }
        mb={[20]}
      >
        {t("ourExpertsAreLicensedBy")}
      </Text>

      <Box
        _before={{
          content: '""',
          position: "absolute",
          left: 0,
          top: 0,
          width: "100px",
          height: "100%",
          background: "linear-gradient(to right, #f8f8f8, transparent)",
          zIndex: 1,
        }}
        _after={{
          content: '""',
          position: "absolute",
          right: 0,
          top: 0,
          width: "100px",
          height: "100%",
          background: "linear-gradient(to left, #f8f8f8, transparent)",
          zIndex: 1,
        }}
        width={["100%", "100%", "95%", "80%"]}
        overflow="hidden"
        position="relative"
      >
        <Box
          style={{
            display: "inline-flex",
            whiteSpace: "nowrap",
          }}
        >
          <motion.div
            style={{
              display: "flex",
              gap: "4rem",
              padding: "2rem 0",
            }}
            animate={{
              x: [0, -(200 * companies.length + 64 * companies.length)], // Width of logos + gaps
            }}
            transition={{
              duration: 15,
              repeat: Infinity,
              ease: "linear",
            }}
            // whileHover={{ animationPlayState: "paused" }}
          >
            {[...companies, ...companies, ...companies, ...companies].map(
              (company, index) => (
                <Box
                  key={`${company.id}-${index}`}
                  flexShrink={0}
                  width="200px"
                >
                  <motion.div
                    whileHover={{ scale: 1.1 }}
                    transition={{ duration: 0.1 }}
                  >
                    <Image
                      src={company.img}
                      alt={company.alt}
                      height={70}
                      style={{
                        objectFit: "contain",
                        width: "100%",
                      }}
                    />
                  </motion.div>
                </Box>
              )
            )}
          </motion.div>
        </Box>
      </Box>
    </section>
  );
};

export default CompanyCooperation;
