//Core
import { useEffect, useRef, useState } from "react";
//Router
import { Link, useSearchParams, useLocation } from "react-router-dom";
//Styles
import Styles from "./styles.module.scss";
//Images
import logo from "../../assets/images/logo.svg";
//Translation
import i18next from "i18next";
import { useTranslation } from "react-i18next";
//Components
import { Box, Button, CenterRow } from "components/Core";
import { routes } from "constants/index";
import { IconButton } from "@mui/material";
import { ArrowBack } from "@mui/icons-material";
import { LoginButton } from "components/shared/LoginButton";

const Header = ({
  header,
  consultButton,
  navigateTo,
  hideSchoolNav,
  hideSignIn,
  languageBlockDisplayMediaQuery = ["none", "none", "none", "none", "block"],
}) => {
  const { t, i18n } = useTranslation(["common"]);
  const [searchParams, setSearchParams] = useSearchParams();
  const { pathname } = useLocation();
  const headerMobileRef = useRef(null);

  const [isOpenHeaderMenu, setIsOpenHeaderMenu] = useState(false);

  const handleShowHeaderMenu = () => setIsOpenHeaderMenu(!isOpenHeaderMenu);
  const handlePreOpenConsultation = () => {
    searchParams.set("modal", "hint");
    setSearchParams(searchParams);
  };

  useEffect(() => {
    if (searchParams.get("lang")) {
      i18next.changeLanguage(searchParams.get("lang"));
    } else if (localStorage.getItem("i18nextLng")?.length > 2) {
      i18next.changeLanguage("ar");
    }
    setSearchParams({ lang: localStorage.getItem("i18nextLng") });
  }, []);

  const handleLanguageChange = (lang) => {
    i18n.changeLanguage(lang);
    setSearchParams({ lang: lang });
  };

  const currentLang = localStorage.getItem("i18nextLng");

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        headerMobileRef.current &&
        !headerMobileRef.current.contains(event.target)
      ) {
        setIsOpenHeaderMenu(false);
      }
    };

    document.addEventListener("click", handleClickOutside);

    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);

  return (
    <header className={Styles.container}>
      <div className={Styles.header}>
        <div className={Styles.header_logo}>
          {navigateTo ? (
            <Link to={navigateTo}>
              <IconButton>
                <ArrowBack />
              </IconButton>
            </Link>
          ) : (
            <Link to={"/"}>
              <Box width={[100, 100, 100, 145]}>
                <img width={"100%"} src={logo} alt={"logo"} />
              </Box>
            </Link>
          )}
        </div>

        {header && (
          <div
            style={{ gap: 10 }}
            className={`${Styles.header_interaction} ${
              pathname === "/join-us" && Styles.only_logo_block
            }`}
          >
            <Box display={languageBlockDisplayMediaQuery}>
              <button
                className={
                  currentLang === "en"
                    ? Styles.lang_select_active
                    : Styles.lang_select
                }
                onClick={() => handleLanguageChange("en")}
              >
                English
              </button>
              |
              <button
                className={
                  currentLang === "ar"
                    ? Styles.lang_select_active
                    : Styles.lang_select
                }
                onClick={() => handleLanguageChange("ar")}
              >
                العربية
              </button>
            </Box>
            {consultButton && (
              <Link to="/join-our-network" className={Styles.therapist_btn}>
                {t("buttons.joinNetwork")}
              </Link>
            )}
            {!hideSchoolNav && (
              <Box ml={[2, 2, 2, 20]}>
                <Link to={routes.school} className={Styles.therapist_btn}>
                  {t("buttons.forSchool")}
                </Link>
              </Box>
            )}
            <CenterRow gap={2}>
              {consultButton && (
                <Button
                  width="max-content"
                  variant="smallCTA"
                  ml={[0, 0, 0, 42]}
                  onClick={handlePreOpenConsultation}
                  // className={Styles.consult_btn}
                >
                  {t("buttons.getStartedToday")}
                </Button>
              )}
              {!hideSignIn && <LoginButton label={t("buttons.signIn")} />}
            </CenterRow>
          </div>
        )}
        {pathname !== "/join-us" && (
          <>
            <div
              ref={headerMobileRef}
              onClick={handleShowHeaderMenu}
              className={Styles.menu_bar}
            >
              {Array.from({ length: 4 }).map((_) => (
                <div class={Styles.menu_line}></div>
              ))}
            </div>
            {isOpenHeaderMenu && (
              <Box
                bottom={!hideSchoolNav ? [-130] : ["-85px"]}
                className={Styles.mobile_header_menu}
                gap={2}
                py={2}
              >
                <CenterRow gap={2}>
                  <Button
                    color={currentLang === "en" ? "primary" : "black.900"}
                    variant="ghost"
                    onClick={() => handleLanguageChange("en")}
                  >
                    English
                  </Button>
                  |
                  <Button
                    variant="ghost"
                    color={currentLang === "ar" ? "primary" : "black.900"}
                    onClick={() => handleLanguageChange("ar")}
                  >
                    العربية
                  </Button>
                </CenterRow>
                {consultButton && (
                  <Link to="/join-our-network">
                    {t("buttons.becomeTherapist")}
                  </Link>
                )}
                {!hideSchoolNav && (
                  <Box>
                    <Link to={routes.school} className={Styles.therapist_btn}>
                      {t("buttons.forSchool")}
                    </Link>
                  </Box>
                )}
                <CenterRow gap={2} mb={2}>
                  {consultButton && (
                    <Button
                      variant="smallCTA"
                      onClick={handlePreOpenConsultation}
                    >
                      {t("buttons.getStartedToday")}
                    </Button>
                  )}
                  {!hideSignIn && <LoginButton label={t("buttons.signIn")} />}
                </CenterRow>
              </Box>
            )}
          </>
        )}
      </div>
    </header>
  );
};

export default Header;
