//Styles
import Styles from "./styles.module.scss";
//Traslation
import { useTranslation } from "react-i18next";
//Core
import { useEffect, useRef, useState } from "react";
import { Box, CenterRow, Flex, Text } from "components/Core";
import { LandingSectionContainer } from "components/shared/LandingSectionContainer";
import { videoOverlay } from "assets/images";
import { IconButton } from "@mui/material";
import { LinkedIn } from "assets/icons";
import { theme } from "theme/theme";
import { useLocal } from "hooks/useLocal";

type Props = {
  title?: string;
  description?: string;
};
const Mission = ({ description, title }: Props) => {
  const { isArabic } = useLocal();
  const [videoId, setVideoId] = useState("https://youtu.be/nvQy6xYI2OA");
  const [play, setPlay] = useState(false);
  const videoOverlayRef = useRef<HTMLDivElement>(null);
  const playVideo = (e: any) => {
    setPlay(true);

    let videoOverlay = videoOverlayRef.current as unknown;
    (videoOverlay as HTMLDivElement).style.display = "none";

    let video = document.getElementById("video");

    // Update the src attribute to include autoplay=1
    let currentSrc = video?.getAttribute("src");
    video?.setAttribute(
      "src",
      currentSrc?.replace("autoplay=0", "autoplay=1") as string
    );
  };

  useEffect(() => {
    if (play && videoOverlayRef.current) {
      videoOverlayRef.current.style.display = "none";
    }
  }, [play, videoOverlayRef.current]);
  const { t, i18n } = useTranslation(["common"]);
  const textDirection = i18n.language === "en" ? "ltr" : "rtl";

  useEffect(() => {
    if (i18n.language === "en") {
      setVideoId("nvQy6xYI2OA");
    } else {
      setVideoId("ZSSo74-0QLw");
    }
  }, [i18n.language]);
  return (
    <LandingSectionContainer
      gap={[20, 20, 20, 30]}
      mb={[30, 30, 30, 60]}
      height="auto"
      flexDirection={[
        "column-reverse",
        "column-reverse",
        "column-reverse",
        "row",
      ]}
    >
      <Flex
        gap={[10, 10, 10, 20]}
        flexGrow={1}
        flexDirection={["column"]}
        direction={textDirection}
        width={["100%", "100%", "100%", "50%"]}
      >
        <Text variant="body3">“{description || t("mission.mission")}”</Text>
        <Flex
          flexDirection={["column"]}
          alignItems={["flex-end", "flex-end", "flex-end", "flex-start"]}
        >
          <Flex flexDirection={["column"]}>
            <Text variant="body4">{t("mission.founderName")}</Text>
            <CenterRow gap={2}>
              <Text borderBottom={1}>{t("mission.founder")}</Text> |
              <a
                href="https://www.linkedin.com/in/ingyalireza/"
                target="_blank"
              >
                <IconButton sx={{ bgcolor: theme.colors.black[200] }}>
                  <LinkedIn />
                </IconButton>
              </a>
            </CenterRow>
          </Flex>

          {/* <div className={Styles.logo} /> */}
        </Flex>
      </Flex>
      <Flex
        width={["100%", "100%", "100%", "100%", "100%", "50%"]}
        gap={[20]}
        flexDirection={["column"]}
      >
        <Text textAlign={isArabic ? "right" : "left"} variant="heading1">
          {title || t("mission.title")}
        </Text>
        <Box>
          {/* {!play ? ( */}

          <Box
            position="relative"
            height={[280, 280, 330]}
            width={["100%", "100%", "98%"]}
          >
            {play ? (
              <iframe
                style={{ width: "100%", height: "100%", borderRadius: "10px" }}
                src={`https://www.youtube.com/embed/${videoId}?enablejsapi=1&version=3&playerapiid=ytplayer&rel=0&modestbranding=0&showinfo=0&controls=1&autoplay=0`}
                id="video"
                title="Numuw Platform"
                frameBorder="0"
                allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
              ></iframe>
            ) : (
              <Box
                ref={videoOverlayRef}
                backgroundImage={`url(${videoOverlay})`}
                onClick={playVideo}
                backgroundPosition="center"
                backgroundSize="cover"
                backgroundRepeat="no-repeat"
                height={[290, 290, 345]}
                //   minWidth={[200]}
                width={["100%"]}
                borderRadius={"5px"}
                cursor="pointer"
                position="absolute"
                //   top={[0, 0, -2, -2]}
                top={[-3]}
              ></Box>
            )}
          </Box>
        </Box>
      </Flex>
    </LandingSectionContainer>
  );
};

export default Mission;
