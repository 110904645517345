//Core
import { useSearchParams } from "react-router-dom";
//Styles
import Styles from "./styles.module.scss";
//Traslation
import { useTranslation } from "react-i18next";
//Components
import PreConsultationPopup from "../PreConsultationPopup";
import { Box, Button, CenterRow } from "components/Core";
import { LoginButton } from "components/shared/LoginButton";

const Footer = ({ hideGetStarted }) => {
  const { t, i18n } = useTranslation(["common"]);
  const textDirection = i18n.language === "en" ? "ltr" : "rtl";

  const [searchParams, setSearchParams] = useSearchParams();

  const handleOpenConsultation = () => {
    searchParams.set("modal", "hint");
    setSearchParams(searchParams);
  };

  return (
    <>
      <footer>
        <div className={Styles.first_block}>
          <div className={Styles.logo} />
          <p dir={textDirection}>{t("footer.description")}</p>
          <div className={Styles.second_block__second_line_container}>
            <a
              className={Styles.private_police}
              href="/terms-of-use"
              target="_blank"
              rel="noreferrer"
            >
              Terms of Use
            </a>

            <a
              className={Styles.private_police}
              href="/privacy-policy"
              target="_blank"
              rel="noreferrer"
            >
              Privacy policy
            </a>
          </div>
        </div>
        <div className={Styles.third_block}>
          {!hideGetStarted && (
            <div className={Styles.footer_buttons}>
              <CenterRow
                flexDirection={["row", "row", "column"]}
                mt={"20px"}
                gap={[10, 10, 10]}
                dir={textDirection}
              >
                <Button variant="smallCTA" onClick={handleOpenConsultation}>
                  {t("buttons.getStartedToday")}
                </Button>

                <LoginButton
                  width={["auto", "auto", "100%"]}
                  label={t("buttons.signIn")}
                />
              </CenterRow>
            </div>
          )}
          <ul className={Styles.footer_socials}>
            <a
              href="https://www.instagram.com/numuwhub/"
              target="_blank"
              rel="noreferrer"
            >
              <li className={Styles.social_insta}></li>
            </a>
            <a
              href="https://www.linkedin.com/company/numuw/?viewAsMember=true"
              target="_blank"
              rel="noreferrer"
            >
              <li className={Styles.social_in}></li>
            </a>
          </ul>
          <div className={Styles.second_block__second_line_container_mobile}>
            <a
              className={Styles.private_police}
              href="/terms-of-use"
              target="_blank"
              rel="noreferrer"
            >
              Terms of Use
            </a>

            <a
              className={Styles.private_police}
              href="/privacy-policy"
              target="_blank"
              rel="noreferrer"
            >
              Privacy policy
            </a>
          </div>
        </div>
      </footer>
    </>
  );
};

export default Footer;
